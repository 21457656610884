import { ThemeProvider } from 'styled-components';
import '../App.less';
import theme from '../theme';
import PopupAdModal from '../components/PopupAdModal';
import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { Provider } from 'mobx-react';
import Router from 'next/router';
import { initializeStore } from '../mobx/RootStore';
import ErrorBoundary from '../common/ErrorBoundary';
import NavBar from '../components/Navbar';
import { getSnapshot } from 'mobx-state-tree';
import * as Sentry from '@sentry/browser';
import pathToRegexp from 'path-to-regexp';
import * as gtag from '../lib/gtag';
import { Mobile } from '../config';
import { getEnv, getFromLocal, setLocalStorage } from '../utilNoSSR';
import mitt from '../common/SMEvent.js';
import { EVENT } from '../common/SMEvent';
import api, { _axios } from '../api';
import { getCurrentCity, getImageMobileDetail } from '../util';
import MobileDetect from 'mobile-detect';
import Cookies from 'universal-cookie';
import moment from 'moment';

Router.events.on('routeChangeComplete', url => {
  gtag.pageview(url);
  gtag.manuallyRandomPageView(url)
});

const withBottomBar = [
  '/',
  '/index',
  '/me',
  '/message',
  '/vehicle-valuation',
  '/new/category',
  '/password/forgot',
  '/topicNotFound'
];

const withADPage = [
  '/',
  '/index',
  '/car',
  '/rentalHomePage',
  '/ershou',
  '/jobHomePage',
  '/localServicesHomePage',
  '/generalHomePage/huodong',
  '/generalHomePage/shengyi',
  '/generalHomePage/lvyou',
  '/socialHomepage',
  '/article/[id]'
];

const privatePage = [
  '/authentication',
  '/auth/setPassword',
  '/merchantRegister',
  '/merchantRegister/step2',
  '/merchantRegister/step3',
  '/valuation-result',
  '/vehicle-Value',
  '/manual-valuation',
  '/topic/edit/[id]',
  '/merchant/:merchantId/edit',
  '/me/topics',
  '/me/merchant2',
  '/me/wallet',
  '/me/watchlist',
  '/message',
  '/conversation',
  '/order/payNotify',
  '/admin/simulate-login',
  '/group/myGroupList',
  '/group/members/[groupId]',
  '/group/shareGroup/[groupId]',
  '/group/selectGroup/',
  '/topic/:topicId/renew/result/:result',
  '/new/social/[id]',
  '/new/service/[id]',
  '/new/event/[id]',
  '/new/business/[id]',
  '/new/general-post/[id]',
  '/systemMessage',
  '/commentsMessage',
  '/verifyEmail',
  // '/event/apply/[id]',
  '/event/ezhiform', //e 智论坛调查问卷
  '/event/apply/duorou', //建筑业协会，多肉展
  '/event/apply/internet', //等风来meet up
  '/event/apply/terra', //terra
  '/event/apply/silvermoon', //
  '/event/apply/aishou', //
  '/event/apply/guojiangjun', //
  '/event/apply/zangzangcha', //
  '/event/apply/zene', //
  '/event/silvermoon_akl', //
  '/event/silvermoon_chc', //
  '/event/oko', //
  '/event/rio', //
  '/event/crimson', //
  '/event/crimson2', //
  '/event/kvb', //
  '/event/1on1lab', //
  '/event/jkd', //
  '/event/focus-education', //
  '/event/1on1Lab2', //
  '/event/xiaotianshi', //
  '/event/wukong', //
  '/event/sp', //
  '/event/edu_experts', //
  '/event/weDance', //
  '/event/reading', //
  '/event/dajiaoya', //
  '/event/art-studio', //
  '/event/qiyuan', //
  '/event/chenxing', //
  '/event/dangxia', //
  '/event/sp2', //
  '/event/chinz88',
  '/event/apply/mooncake'
];

const sharedPage = [
  '/topic/[id]',
  '/pages/article/[id]',
  '/event/apply/[id]',
  '/merchant/[merchantId]'
];

const regexp = privatePage.map(path => pathToRegexp(path));
const regexpAD = withADPage.map(path => pathToRegexp(path));
const regexpShared = sharedPage.map(path => pathToRegexp(path));

const PSWP = () => {
  return (
    <div className='pswp' tabIndex='-1' role='dialog' aria-hidden='true'>
      <div className='pswp__bg' />
      <div className='pswp__scroll-wrap'>
        <div className='pswp__container'>
          <div className='pswp__item' />
          <div className='pswp__item' />
          <div className='pswp__item' />
        </div>
        <div className='pswp__ui pswp__ui--hidden'>
          <div className='pswp__top-bar'>
            <div className='pswp__counter' />
            <button
              className='pswp__button pswp__button--close'
              title='Close (Esc)'
            />
            <div className='pswp__preloader'>
              <div className='pswp__preloader__icn'>
                <div className='pswp__preloader__cut'>
                  <div className='pswp__preloader__donut' />
                </div>
              </div>
            </div>
          </div>
          <div className='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
            <div className='pswp__share-tooltip' />
          </div>
          <button
            className='pswp__button pswp__button--arrow--left'
            title='Previous (arrow left)'
          />
          <button
            className='pswp__button pswp__button--arrow--right'
            title='Next (arrow right)'
          />
          <div className='pswp__caption'>
            <div className='pswp__caption__center' />
          </div>
        </div>
      </div>
    </div>
  );
};

const isServer = typeof window === 'undefined';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://064fe5f3e86d43bf9bf08cbeebf7ae6b@sentry.io/197679'
  });
}

class MyApp extends App {
  shareTitle = '找什么，神马都有！';
  shareContent = '神马网，新西兰中文社区。21万本地用户，每天数万浏览。上神马发帖，让更多人看到';
  shareImage = null;
  shareUrl = null;

  static async getInitialProps({ Component, ctx }) {
    const { res, req, pathname } = ctx;
    const store = initializeStore(isServer);
    let jwt = null;
    if (req) {
      const cookie = new Cookies(req.headers.cookie);
      jwt = cookie.get('jwt');
    } else {
      const cookie = new Cookies();
      jwt = cookie.get('jwt');
    }
    const query = ctx.query;

    // 微信小程序登陆后
    if (query.jwt) {
      jwt = query.jwt;
    }
    //微信 web 登录
    const code = query.code;
    /****** route intercept start ******/
    if (
      !jwt &&
      !code &&
      regexp.some(reg => {
        return reg.test(pathname);
      })
    ) {
      if (res) {
        store.uiStore.setAfterRegisterPath(pathname);
        res.writeHead(302, {
          Location: '/login?path=' + pathname
        });
        res.end();
      } else {
        store.uiStore.requireLogin(pathname);
      }
    }
    /****** route intercept end *******/

    /****** Mobile or Desktop start *******/
    const header = req ? req.headers['user-agent'] : null;
    if (res && header) {
      const md = new MobileDetect(header);
      const url = req.url;
      const isTopic = new RegExp('/topic/[0-9]*$');
      const isArticle = new RegExp('/article/[0-9]*$');
      if (!md.mobile()) {
        if (isTopic.test(url) || isArticle.test(url)) {
          res.writeHead(302, {
            Location: 'https://shenmanz.com' + url
          });
          res.end();
        }
      }
    }
    /****** Mobile or Desktop end *******/

    /****** init Store start *******/
    if (isServer) {
      //强制删除 header
      delete _axios.defaults.headers.common['Authorization'];
      await store.configStore.init();
    }
    /****** init Store end *******/

    /****** invoke sub page GIP start *******/
    ctx.rootStore = store;
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    /****** invoke sub page GIP end *******/

    return { pageProps, initialState: getSnapshot(store), jwt };
  }

  constructor(props) {
    super(props);
    this.store = initializeStore(isServer, props.initialState);
    this.state = {
      showPopupAdModal: false,
      popUpAdImage: '',
      popUpAdLink: '',
      popUpAdTuantuan: 0
    };
    this.wx = null;
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.href
    ) {
      window.entryUrl = window.location.href.split('#')[0];
    }

    if (!isServer) {
      this.initApp();
    }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }

    super.componentDidCatch(error, errorInfo);
  }

  initShareContent = () => {
    this.shareTitle = '找什么，神马都有！';
    this.shareContent = '神马网，新西兰中文社区。21万本地用户，每天数万浏览。上神马发帖，让更多人看到';
    this.shareImage = null;
  };

  componentWillUnmount() {
    mitt.off(EVENT.SET_SHARE_CONTENT, () => {
    });
    mitt.off(EVENT.USER_STATUS_401, () => {
    });
  }

  isPoped = () => {
    const last = getFromLocal('popup_time');
    setLocalStorage('popup_time', moment().format('YYYY-MM-DD'));
    if (last) {
      const now = moment().format('YYYY-MM-DD');
      return moment(now).isSame(last);
    }
    return false;
  };

  getExclude = () => {
    const last = getFromLocal('popup_time');
    setLocalStorage('popup_time', moment().format('YYYY-MM-DD'));
    if (last) {
      const now = moment().format('YYYY-MM-DD');
      if (moment(now).isSame(last)) {
        return getFromLocal('exclude');
      } else {
        setLocalStorage('exclude', []);
        return null;
      }
    } else {
      setLocalStorage('exclude', []);
      return null;
    }
  };

  setExclude = id => {
    const ids = getFromLocal('exclude');
    if (ids) {
      setLocalStorage('exclude', [id, ...ids]);
    } else {
      setLocalStorage('exclude', [id]);
    }
  };

  async componentDidMount() {
    gtag.manuallyRandomPageView(window.location.href)
    const route = this.props.router.route;
    //允许弹窗的页面
    const exclude = this.getExclude();
    const city = await getCurrentCity();
    if (regexpAD.some(reg => reg.test(route))) {
      const { data } = await api.Ad.popup({
        exclude: exclude,
        city: city
      });
      if (data && !data.code) {
        this.setExclude(data.id);
        const { link } = data;
        // 当前位于弹窗目标页
        if (link &&
          (link === Router.router.asPath ||
          (link.includes('/tuan/') && route === '/tuan'))
        ) {
          return;
        }
        //当日尚未弹窗
        this.setState({
          popupId: data.id,
          showPopupAdModal: true,
          popUpAdImage: data.image,
          popUpAdLink: data.link || '',
          popUpAdTuantuan: data.tuantuan || 0
        });
      }else {
        setLocalStorage('exclude', []);
      }
    }
  }

  initWeChatShare = () => {
    if (getEnv() !== 'wx' && getEnv() !== 'miniProgram') {
      return;
    }
    if (!window.WeixinJSBridge || !window.WeixinJSBridge.invoke) {
      document.addEventListener(
        'WeixinJSBridgeReady',
        () => {
          this.wxReady();
        },
        false
      );
    } else {
      this.wxReady();
    }
  };

  wxReady = async () => {
    if (!this.wx) {
      return;
    }
    //更新小程序的分享地址
    const that = this;
    this.wx.miniProgram.getEnv(function(res) {
      if (res.miniprogram) {
        that.wx.miniProgram.postMessage({
          data: {
            webViewUrl: that.shareUrl || window.location.href,
            title: that.shareTitle
          }
        });
      }
    });

    //更新微信 web 分享
    const { getEnv } = require('../utilNoSSR');
    if (getEnv() === 'wx') {
      const { isiOS } = require('../utilNoSSR');
      const { data } = await api.wxWeb.getJSSDKConfig({
        url: isiOS ? window.entryUrl : window.location.href.split('#')[0]
      });
      const image = this.shareImage
        ? getImageMobileDetail(this.shareImage)
        :'https://res.cloudinary.com/shenmanz/image/upload/v1633990729/shenma/banner/WechatIMG7.jpg';
      if (data && !data.code) {
        const config = data.data
        this.wx.config(config);
        const wx = this.wx;
        const apiList = config['jsApiList'];
        this.wx.ready(function() {
          wx.checkJsApi({
            jsApiList: apiList, // 需要检测的JS接口列表,
            success: function(res) {
              const { checkResult } = res;
              if (checkResult.updateAppMessageShareData) {
                //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
                wx.updateAppMessageShareData({
                  title: that.shareTitle, // 分享标题
                  desc: that.shareContent || '新西兰最大中文分类信息网站', // 分享描述
                  link: that.shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: image, // 分享图标
                  success: function() {
                    // 设置成功
                  }
                });
              } else {
                // 即将废弃接口
                wx.onMenuShareAppMessage({
                  title: that.shareTitle, // 分享标题
                  desc: that.shareContent || '新西兰最大中文分类信息网站', // 分享描述
                  link: that.shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: image // 分享图标
                });
              }
              if (checkResult.updateTimelineShareData) {
                //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
                wx.updateTimelineShareData({
                  title: that.shareTitle, // 分享标题
                  link: that.shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: image, // 分享图标
                  success: function() {
                    // 设置成功
                  }
                });
              } else {
                // 即将废弃接口
                wx.onMenuShareTimeline({
                  title: that.shareTitle, // 分享标题
                  link: that.shareUrl || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: image // 分享图标
                });
              }
            },
          });
        });
      }
    }
  };

  async initApp() {
    //init wechat share
    this.wx = require('weixin-js-sdk');
    const route = this.props.router.route;
    this.initShareContent();
    if (!regexpShared.some(reg => reg.test(route))) {
      this.initWeChatShare();
    }
    //开启微信分享设置的监听
    mitt.on(EVENT.SET_SHARE_CONTENT, ({ url, title, content, image }) => {
      console.log('设置分享：',url, title, content, image)
      this.shareTitle = title;
      this.shareContent = content;
      this.shareImage = image;
      this.shareUrl = url;
      this.initWeChatShare();
    });
    mitt.on(EVENT.USER_STATUS_401, () => {
      this.store.userStore.logout();
      this.store.uiStore.requireLogin(Router.router.asPath);
    });
    Router.events.on('routeChangeStart', url => {
      this.shareUrl =
        window.location.protocol + '//' + window.location.host + url;
      this.initShareContent();
    });
    Router.events.on('routeChangeComplete', r => {
      const route = this.props.router.route;
      if (regexpShared.some(reg => reg.test(route))) {
        return;
      }
      this.initWeChatShare();
    });
    // 小程序登录
    const { jwt } = this.props.router.query;
    if (jwt) {
      this.store.userStore.updateJWT(jwt);
      await this.store.userStore.getUserProfile();
    }

    //客户端初始化请求头
    let jwt_local = null;
    try {
      jwt_local =
        jwt || this.store.userStore.jwt || localStorage.getItem('jwt');
    } catch (e) {
    }
    if (jwt_local) {
      this.store.userStore.updateJWT(jwt_local);
      this.store.userStore.initUserStore();
    }
    const { gtag } = window;
    gtag('config', 'UA-101477606-1', { page_path: window.location.pathname });
  }

  render() {
    const { Component, pageProps } = this.props;
    const route = this.props.router.route;
    const {
      showPopupAdModal,
      popUpAdImage,
      popUpAdLink,
      popUpAdTuantuan,
      popupId
    } = this.state;
    let childComponent = <Component {...pageProps} />;
    return (
      <div>
        <Head>
          <title>神马网，新西兰中文集市</title>
          <meta name='theme-color' content='#ee8100' />
          <meta name='apple-mobile-web-app-title' content='神马网' />
          <meta name='apple-mobile-web-app-capable' content='yes' />
          <meta
            name='description'
            content='新西兰神马网是新西兰最大的中文分类信息网站，您可以免费查找新西兰最新最全的二手物品交易、二手车买卖、房屋租售、宠物、招聘、兼职、求职、交友活动、生活服务信息。还能免费发布这些信息。'
          />
          <link rel='manifest' href='/static/manifest.json' />
          <link rel='shortcut icon' href='/static/favicon.ico' />
          <link rel='stylesheet' href='/static/photoswipe/photoswipe.css' />
          <link
            rel='stylesheet'
            href='//at.alicdn.com/t/font_876372_j69zdanif.css'
          />
          <link
            rel='stylesheet'
            href='/static/photoswipe/default-skin/default-skin.css'
          />
          <link
            rel='stylesheet'
            type='text/css'
            charSet='UTF-8'
            href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          />
          <link
            rel='stylesheet'
            type='text/css'
            href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
          />
          {getEnv() !== 'miniProgram' && typeof window !== 'undefined' && (
            <>
              <script src='https://js.stripe.com/v3/' />
              <script
                async
                src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
              ></script>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                    (adsbygoogle = window.adsbygoogle || []).push({
                      google_ad_client: 'ca-pub-6984675665005390',
                      enable_page_level_ads: true
                    })
                  `
                }}
              />
            </>
          )}
          <noscript>You need to enable JavaScript to run this app.</noscript>
        </Head>
        <ErrorBoundary>
          <div
            className='main'
            style={
              withBottomBar.indexOf(route) > -1 ? { paddingBottom: 60 } : null
            }
          >
            <ThemeProvider theme={theme}>
              <Provider {...this.store}>
                <div>
                  {childComponent}
                  {withBottomBar.indexOf(route) > -1 && (
                    <NavBar pathname={route} />
                  )}
                  <Mobile>
                    <PopupAdModal
                      popupId={popupId}
                      show={showPopupAdModal}
                      popUpAdImage={popUpAdImage}
                      link={popUpAdLink}
                      tuantuan={popUpAdTuantuan}
                      onClose={() => {
                        this.setState({ showPopupAdModal: false });
                      }}
                    />
                  </Mobile>
                </div>
              </Provider>
            </ThemeProvider>
          </div>
        </ErrorBoundary>
        <PSWP />
      </div>
    );
  }
}

export default MyApp;
