import React from 'react';
import styled from 'styled-components';

class PostGuide extends React.PureComponent<any> {

  render() {
    return (
      <AutoRenewTipsDiv show={this.props.show} {...this.props}>
        <>
          <div className='content'> {this.props.content} </div>
          <i className='triangle' />
        </>
      </AutoRenewTipsDiv>
    );
  }
}

export default PostGuide;

const AutoRenewTipsDiv = styled.div`
  position: absolute;
  left: 14px;
  ${props => `
    ${props.top ? ' top: 16px' : ' bottom: 26px'};`};
  z-index: 10;

  ${props => `
    ${props.show ? '' : 'display: none'};`}
  .triangle {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    ${props => `
        ${props.top ? ' border-bottom' : ' border-top'}`}: 10px solid rgb(74, 144, 226);
    ${props => `
    ${props.left ? ' margin-left: 10px;' : ''}`}
    ${props => `
    ${props.right ? ` margin-left: ${props.content.length * 14 - 34}px;` : ''}`}
    ${props => `
    ${
      props.middle
        ? ` margin-left: ${(props.content.length * 14) / 2 - 10}px;`
        : ''
    }`}
  }

  .content {
    width: ${props => `${props.content ? props.content.length * 14 : 0}px`};
    border-radius: 5px;
    background: rgb(74, 144, 226);
    color: white;
    font-size: 14px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    ${props => `
        ${props.top ? ' margin-top' : ' margin-bottom'}
        : 10px;`}
  }
`;
