export const GA_TRACKING_ID = 'UA-101477606-1';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

export const manuallyRandomPageView = (url) => {
  const maxTimes = 4;
  const randomTimes = Math.floor(Math.random() * (maxTimes + 1));
  for (let i = 0; i <= randomTimes; i++) {
    window.gtag('event', 'page_view', {
      page_path: url,
      send_to: GA_TRACKING_ID
    });
  }

};
